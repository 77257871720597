<template>
  <LayoutGridBox :id="`grid-${_id}`" :permalink="permalink" :label="label">
    <LayoutGridBoxImageSection
      class="brand-logo relative"
      :src="logo"
      :alt="brand?.name"
      :sizes="sizes"
    >
      <template #meta>
        <span v-if="sticky" class="absolute left-0 top-0">{{
          stickyLabel
        }}</span>
        <time class="absolute right-0 top-0" :datetime="timeUtc">{{
          timeAgo
        }}</time>
      </template>
    </LayoutGridBoxImageSection>

    <p class="mb-2 font-bold text-category">
      {{ career?.name }}
    </p>
    <h2 class="mb-2">
      {{ title }}
    </h2>
    <h3 class="mt-auto font-bold">
      {{ brand?.name }}
    </h3>
    <p>{{ description }}</p>
  </LayoutGridBox>
</template>

<script setup lang="ts">
import type { QueryOpportunities as Opportunities } from "~/@types/opportunities"

const props = defineProps<{
  post: Opportunities
}>()

const dayjs = useDayjs()
const {
  _id,
  title,
  logo,
  permalink,
  brand,
  career,
  description,
  publishedAt,
  sticky,
  stickyLabel,
} = useOpportunities(props.post)

const { sizes } = useImageInfo(logo)
const label = computed(() => `${brand?.name} - ${title}`)
const timeUtc = computed(() => dayjs(publishedAt).utc().toString())
const timeAgo = computed(() => dayjs(publishedAt).toNow(true))
</script>
